import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";

const theadings = [
	{
		title: "Sl No.",
		width: "50px",
	},
	{
		title: "Titan User ID",
		width: "",
	},
	{
		title: "Name of the Applicant",
		width: "",
	},
	{
		title: "Startup Name",
		width: "",
	},
	{
		title: "Titan Project ID",
		width: "",
	},
	{
		title: "Project Title",
		width: "",
	},
	{
		title: "Sector",
		width: "",
	},
];

const ScoreCardTable = ({ persons, applications }) => {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{theadings?.map((heading, i) => {
							return (
								<TableCell align="center" width={heading.width} key={i}>
									{heading.title}
								</TableCell>
							);
						})}

						{persons?.map((person, i) => {
							return (
								<TableCell align="center" key={i}>
									{person.abbr}
								</TableCell>
							);
						})}

						<TableCell align="center">Total</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{applications?.map((application, i) => {
						let total = 0;
						return (
							<TableRow key={i} className="pointer">
								<TableCell align="center">{i + 1}</TableCell>
								<TableCell align="center">
									{application?.userId ? application?.userId : "-"}
								</TableCell>
								<TableCell align="center">
									{application?.applicantName
										? application?.applicantName
										: "-"}
								</TableCell>
								<TableCell align="center">{application?.companyName}</TableCell>
								<TableCell align="center">
									{application?.projectId ? application?.projectId : "-"}
								</TableCell>
								<TableCell align="center">
									{application?.projectTitle ? application?.projectTitle : "-"}
								</TableCell>
								<TableCell align="center">-</TableCell>
								{persons?.map((person, i) => {
									total +=
										application?.applicationData?.phase2?.data?.[person?.id]
											?.total || 0;
									return (
										<TableCell align="center" key={i}>
											{application?.applicationData?.phase2?.data?.[person?.id]
												?.total || 0}
										</TableCell>
									);
								})}
								<TableCell align="center">{total}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ScoreCardTable;
