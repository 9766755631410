// import 'D:\\Lazy Idli\\Athena\\ikp_data_creator\\src\\components\\schemes\\ikp\\prayas\\components\\application\\dueDiligence\\DueDeligence.css'
import "../../../../../../../utils/mediaExports.js";
import { Icons } from "../../../../../../../utils/mediaExports.js";
import {
  Button,
  FormControlLabel,
  Icon,
  Switch,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import "../../../../../../../components/customComponents/CustomButton.jsx";
import CustomButton from "../../../../../../../components/customComponents/CustomButton.jsx";
import { documents } from "./DueDeligence";
import { applicationCount, fetchApplications } from "../../../../../../../data/network/api/scheme";
import { globalDispatch } from "../../../../../../../providers/GlobalProvider";
import { PrayasDispatch } from "../../../../../../../providers/scheme/prayas/PrayasProvider";
import { updatePrayasNotification1 } from "../../../../../../../reducers/schemes/prayas/tabs";
import { useNavigate } from "react-router-dom";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import CustomTextSearch from "../../../../../../customComponents/CustomSearchText";
import { Table } from "react-bootstrap";
import { set } from "immutable";
import { tabs } from "../../Applications.jsx";

// border is darkened between adjacent blocks;

const initialData = {
  teamAadhar: false,
  hardwareDevelopment: false,
  workingFulltime: false,
  underSevenYears: false,
  addressProof: false,
  itReturn: false,
  noc: false,
  cin: false,
  moa: false,
  aoa: false,
  letterOfCommitment: false,
  selfCertificate1: false,
  selfCertificate2: false,
  eligible: false,
};

const tableHeadings = {
  teamAadhar: "Team Aadhar",
  hardwareDevelopment: "Hardware Development",
  workingFulltime: "Working Fulltime",
  underSevenYears: "Under 7 Years",
  addressProof: "Address Proof",
  itReturn: "IT Return",
  noc: "NOC",
  cin: "CIN",
  moa: "MOA",
  aoa: "AOA",
  letterOfCommitment: "Letter of Commitment",
  selfCertificate1: "Self Certificate 1",
  selfCertificate2: "Self Certificate 2",
};

const PHASE = "phase4";

const DueDiligenceApplications = ({ call, active }) => {
  const dispatch = globalDispatch();
  const prayasDispatch = PrayasDispatch();
  const controller = new AbortController();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [applications, setApplications] = useState([]);
  const [width, setWidth] = useState(0);

  const getApplications = async () => {
    applicationCount({ callId: call._id }, dispatch, controller).then(
      (response) => {
        console.log(response);
        Object.keys(tabs).map((key, i) => {
          prayasDispatch(
            updatePrayasNotification1({
              id: tabs[key],
              value: response.msg?.[i] || 0,
            })
          );
        });
      }
    );
    const response = await fetchApplications(
      { callId: call._id, phase: active + 1 },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      prayasDispatch(
        updatePrayasNotification1({
          id: "dueDeligence",
          value: response.msg?.applications?.length || 0,
        })
      );
      setApplications(response.msg?.applications);
    }
  };

  useEffect(() => {
    getApplications();
    const observer = new ResizeObserver((result) => {
      const width = (result[0].target.offsetWidth);
      setWidth(width);
    });
    const elementToObserve = document.getElementById("dueDiligenceTableContainer");
    observer.observe(elementToObserve);

    return () => {
      observer.disconnect();
    }
  }, []);

  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={searchText}
          setValue={(value) => setSearchText(value)}
          upcomingBanner={true}
        />
        <CustomButton
          title="Add New Applicant"
          onClickHandler={() => setOpen(true)}
        />
      </div>

      <TableContainer className="tableContainer dueDiligenceTable" id="dueDiligenceTableContainer">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          cellPadding={0}
          cellSpacing={0}
        >
          <TableHead>
            <TableRow>
              <TableCell width={"90px"} align="center">
                Sl No.
              </TableCell>
              <TableCell align="center" width={"250px"}>Company Name</TableCell>

              {Object.keys(tableHeadings).map((keyname, i) => (
                <TableCell align="center" key={keyname} width={(width-450) / 13}>
                  {tableHeadings[keyname]}
                </TableCell>
              ))}

              <TableCell align="center">Remarks</TableCell>
              <TableCell align="center" width={"50px"}>Eligibility</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {applications?.map((application, i) => {
              const data = application?.applicationData?.[PHASE]?.data;
              const companyName = application?.companyName;
              const sector = application?.sector;

              return (
                <TableRow
                  key={application?._id}
                  onClick={() =>
                    navigate(`/schemes/prayas/${call?._id}/${application?._id}`)
                  }
                >
                  <TableCell align="center" key={i}>
                    {i + 1}
                  </TableCell>
                  <TableCell align="center" key={i}>
                    {companyName}
                  </TableCell>

                  {Object.keys(tableHeadings).map((keyname) => (
                    <TableCell align="center" key={keyname}>
                      {!!data?.[keyname] ? (
                        <span className="yes">Yes</span>
                      ) : (
                        <span className="no">No</span>
                      )}
                    </TableCell>
                  ))}

                  <TableCell align="center" key={i} >
                    {application?.applicationData?.phase4?.comment || "-"}
                  </TableCell>
                  <TableCell align="center" key={i}>
                    {!!data?.eligible ? (
                      <span className="yesBg">{Icons.check}</span>
                    ) : (
                      <span className="noBg">{Icons.close}</span>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </SchemePhaseTabContainer>
  );
};

export default DueDiligenceApplications;

// let rows=[];
// for(let i=0;i<10;i++){
//   if(i%2 != 0){
//     rows.push(<div style={{backgroundColor:'green'}}>
//     Hello
//   </div>);
//   }
//   else{
//     rows.push(<div style={{backgroundColor:'white'}} >
//     Hello
//   </div>);
//   }

// }
// return (rows);

// return (
//   <div className='container'>

//     <table className="parent-grid">

//       <div className='grid-item left-header-item'>
//           Hello
//       </div>

//       <div className='grid-item '>
//           Hello
//       </div>

//     </table>

//     {/* Eligibility Column */}
//     <div className='eligibility-column'>

//     </div>

//   </div>

//   );
