import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { MuiChipsInput } from 'mui-chips-input'
import { useState } from 'react'
import styled from 'styled-components'
import GlobalStore from '../../store/globalStore'
import { globalDispatch } from '../../providers/GlobalProvider'
import {
  DialogBody,
  MultipleImageUploadContainer,
  theme,
} from '../../utils/customStyledComponents'
import { getFileName } from '../../utils/helperHandler'
import { Gif, Icons } from '../../utils/mediaExports'
import CustomButton from '../customComponents/CustomButton'
import { updateDocument } from '../../reducers/DocumentReducer'

const filter = createFilterOptions()

const getHeading = (keyname) => {
  const names = {
    productName: 'Product Name',
    writeUp: 'Write Up',
    productGallery: 'Product Gallery',
    trl: 'TRL Level',
    technicalData: 'Technical Data',
    workflow: 'Product Workflow',
    specifications: 'Specifications',
    applicant: 'Applicant',
    collaborator: 'Collaborator',
    licensed: 'Licensed',
    jointly: 'Jointly by Company and Collaborator',
    patentStatus: 'Patent Status',
    patentNumber: 'Patent Number',
    patentTitle: 'Patent Title',
    productDetails: 'Product Details',
    jurisdiction: 'Jurisdiction',
    similarPatent: 'Similar Patent',
    name: 'Name',
    registration: 'Registration',
    expiry: 'Expiry',
    currentStatus: 'Current Status',
    type: 'Type',
    description: 'Description',
    designation: 'Designation',
    gender: 'Gender',
    educationQualification: 'Education Qualification',
    workExperience: 'Work Experience',
    phone: 'Phone',
    emailId: 'EmailId',
    pan: 'PAN',
    aadhar: 'Aadhar',
    din: 'DIN',
    isActive: 'Active',
    keyContacts: 'Key Contacts',
    shareholding: 'Share Holding',
    esop: 'ESOP',
    org: 'Org',
    profitShare: 'Profit Share',
    companiesWorked: 'Companies Worked',
    grantName: 'Grant Name',
    projectTitle: 'Project Title',
    projectSummary: 'Project Summary',
    totalGrantAmount: 'Total Grant Amount',
    fundingAgency: 'Funding Agency',
    projectManagementAgency: 'Project Management Agency',
    presentProjectStatus: 'Present Project Status',
    startDate: 'Start Date',
    completionDate: 'End Date',
    dateInvestmentRaised: 'Investment Raised Date',
    investmentKind: 'Investment Kind',
    instrumentType: 'Instrument Type',
    terms: 'Terms',
    valuation: 'Valuation',
    participants: 'Participants',
    exits: 'Exits',
    series: 'Series',
    agency: 'Agency',
    prize: 'Prize Money',
    benefits: 'Benefits',
    year: 'Year',
    annualReport: 'Annual Report',
    balanceSheet: 'Balance Sheet',
    revenue: 'Revenue',
    books: 'Books',
    sector: 'Domain & Sector',
    mentoredCompanies: 'Mentored Companies',
    specialization: 'Specializtion',
    feesCharged: 'Fees Charged',
    advisoryShares: 'Advisory Shares',
    investedSectors: 'Invested Sectors',
    investedCompanies: 'Invested Companies',
    proficiency: 'Proficiency',
    ticketSize: 'Ticket Size',
    investmentInCurrentCompany: 'Investment in Current Company',
    address: 'Address',
    since: 'Since',
    focusAreas: 'Focus Areas',
    mou: 'MOU',
    natureOfWork: 'Nature of Work',
    jobTitle: 'Job Title',
    jobDescription: 'Job Description',
    link: 'Link',
    duration: 'Duration',
    outcome: 'Outcome',
    future: 'Future plans of Commercialization',
    businessPlan: 'Business Plan',
    kpi: 'KPI',
    okr: 'OKR',
  }
  return names?.[keyname]
}

const urlIds = [
  'mou',
  'productGallery',
  'annualReport',
  'balanceSheet',
  'revenue',
  'books',
]

export const BuildComponent = ({
  dataField,
  reducerData,
  handleChange,
  options = {},
  handleImageGallery,
  removeFileFromArray,
  addToArray,
  removeRow,
  handleEditRow,
  isEdit,
  currentEditRow,
  handleFile,
  handleSearch,
  removeFile,
  loading = false,
  view = false,
  associationAddRow,
  submitHandler = () => {},
  removeAssocRow,
  handleAssocChange,
  formFields,
  setDocumentUrl,
  industries,
  schemes,
}) => {
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false)
  const dispatch = globalDispatch()

  let value = ''
  let helperText = ''
  let error = ''
  let isActive = false
  let disabled = false

  try {
    value =
      options?.others == true
        ? reducerData?.others[dataField.fieldName].value
        : reducerData[dataField.fieldName].value

    helperText =
      options?.others == true
        ? reducerData?.others?.[dataField.fieldName].error == ''
          ? ' '
          : reducerData?.others?.[dataField.fieldName].error
        : reducerData[dataField.fieldName].error == ''
          ? ' '
          : reducerData[dataField.fieldName].error

    error =
      options?.others == true
        ? !!reducerData?.others[dataField.fieldName].error
        : !!reducerData[dataField.fieldName].error

    isActive =
      options?.others == true
        ? !!reducerData?.others[dataField.fieldName].active
        : !!reducerData[dataField.fieldName].active
  } catch (e) {}

  const repeatedFields = [
    'phoneNumber',
    'website',
    'officeAddress',
    'city',
    'state',
    'pincode',
    'emails',
  ]

  switch (options.inputType) {
    case 'file':
      return (
        <BuildFileUploader
          dataField={dataField}
          file={reducerData[dataField.fieldName]?.value}
          options={options}
          handleFile={handleFile}
          removeFile={removeFile}
          error={error}
          helperText={helperText}
          view={false}
        />
      )

    case 'text':
      if (
        (dataField.category == 'summary' ||
          dataField.category == 'companyDetails') &&
        repeatedFields.includes(dataField.fieldName)
      ) {
        let data = GlobalStore.getState().startup.contact.contact
        dataField.category = 'contact'
        dataField.subCategory = 'contact'
        helperText = data[dataField.fieldName]?.error || ''
        error = !!data[dataField.fieldName]?.error || false
        isActive = data[dataField.fieldName]?.active || false
        value = data[dataField.fieldName].value
      }
      return (
        <TextField
          label={options.title}
          id={dataField.fieldName}
          size='small'
          onChange={(e) => {
            handleChange(
              dataField.fieldName,
              e.target.value,
              dataField.category,
              dataField.subCategory
            )
          }}
          value={value}
          helperText={helperText}
          error={error}
          multiline={options.multiline}
          rows={options.rows}
          style={
            isActive
              ? options.gridColumn
                ? { gridColumn: options.gridColumn }
                : null
              : { display: 'none' }
          }
        />
      )

    case 'radio':
      return (
        <div
          style={{
            justifyContent: 'center',
            display: isActive ? 'flex' : 'none',
            flexDirection: 'column',
            gridColumn: options.gridColumn || null,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel id='demo-row-radio-buttons-group-label'>
              {options.title}: &nbsp;
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              value={value}
              onChange={(e) => {
                handleChange(
                  dataField.fieldName,
                  e.target.value,
                  dataField.category,
                  dataField.subCategory
                )
              }}
            >
              {options.options.map((option, i) => {
                return (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.title}
                    key={i}
                  />
                )
              })}
            </RadioGroup>
          </div>

          <span>&nbsp;</span>
        </div>
      )

    case 'date':
      return (
        <div style={!isActive ? { display: 'none' } : null}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={options.title}
              inputFormat='DD/MM/YYYY'
              onChange={(newValue) => {
                handleChange(
                  dataField.fieldName,
                  new Date(newValue).toString(),
                  dataField.category,
                  dataField.subCategory
                )
              }}
              value={value}
              renderInput={(params) => (
                <TextField fullWidth {...params} size='small' />
              )}
            />
          </LocalizationProvider>
          <FormHelperText
            style={error ? { color: 'var(--errorTextColor)' } : null}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
          </FormHelperText>
        </div>
      )

    case 'button':
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gridColumn: options.gridColumn || null,
          }}
        >
          <ThemeProvider theme={theme}>
            <Button
              variant='contained'
              onClick={() => {
                if (isEdit) {
                  addToArray(dataField, currentEditRow, true)
                } else {
                  addToArray(dataField)
                }
              }}
              style={{ minWidth: '10rem' }}
            >
              {isEdit ? 'Edit' : options.title}
            </Button>
          </ThemeProvider>
        </div>
      )

    case 'product-gallery':
      return (
        <MultipleImageUploadContainer style={{ gridColumn: '1/-1' }}>
          <FormLabel>
            {options.title}{' '}
            <span className='smallText'>
              (PNG, JPG, JPEG, SVG, GIF, AVIF, MP4)
            </span>
          </FormLabel>
          <div className='image-container'>
            {value.map((image, i) => {
              return (
                <BuildImageViewer
                  key={i}
                  image={image}
                  i={i}
                  removeFileFromArray={removeFileFromArray}
                  dataField={dataField}
                  view={false}
                />
              )
            })}

            <label className='add'>
              <input
                multiple
                type={'file'}
                onChange={(e) =>
                  handleImageGallery(
                    e,
                    dataField.fieldName,
                    dataField.category,
                    dataField.subCategory
                  )
                }
                hidden
              />
              {Icons.plus}
            </label>
          </div>
        </MultipleImageUploadContainer>
      )

    case 'select':
      return (
        <FormControl>
          <InputLabel id='demo-simple-select-label' size='small'>
            {options.title}
          </InputLabel>
          <Select
            error={error}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={value}
            label={options.title}
            size='small'
            onChange={(e) =>
              handleChange(
                dataField.fieldName,
                e.target.value,
                dataField.category,
                dataField.subCategory
              )
            }
          >
            <MenuItem value={-1}>
              <FormLabel>Select {options.title}</FormLabel>
            </MenuItem>
            {options.options.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.title}
                </MenuItem>
              )
            })}
          </Select>

          <FormHelperText
            style={error ? { color: 'var(--errorTextColor)' } : null}
          >
            {helperText ? helperText : <>&nbsp;</>}
          </FormHelperText>
        </FormControl>
      )

    case 'viewer':
      return (
        <div style={{ gridColumn: '1/-1', display: isEdit ? 'none' : null }}>
          {reducerData?.array?.map((object, index) => {
            return (
              <SummaryRow key={index}>
                <div className='content'>
                  {Object.keys(object).map((keyname, i) => (
                    <div className='row'>
                      <div className='heading'>{getHeading(keyname)}</div>
                      {!!getHeading(keyname) ? (
                        <>
                          {keyname == 'startDate' ||
                          keyname == 'completionDate' ||
                          keyname == 'registration' ||
                          keyname == 'dateInvestmentRaised' ||
                          keyname == 'since' ||
                          keyname == 'expiry' ? (
                            <div className='desc'>
                              {new Date(object?.[keyname]).toLocaleDateString()}
                            </div>
                          ) : (
                            <div className='desc'>
                              {urlIds.includes(keyname) ? (
                                keyname == 'productGallery' ? (
                                  object?.[keyname]?.map((url, i) => {
                                    return (
                                      <>
                                        <div
                                          className='pointer'
                                          onClick={() => {
                                            dispatch(
                                              updateDocument({
                                                open: true,
                                                url: url?.url || url,
                                              })
                                            )
                                          }}
                                        >
                                          {i + 1}.{' '}
                                          {getFileName(url?.url || url)}
                                        </div>
                                        &nbsp;
                                      </>
                                    )
                                  })
                                ) : view ? (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        updateDocument({
                                          open: true,
                                          url:
                                            object[keyname]?.url ||
                                            object[keyname],
                                        })
                                      )
                                    }}
                                  >
                                    {i + 1}.{' '}
                                    {getFileName(
                                      object[keyname]?.url || object[keyname]
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      dispatch(
                                        updateDocument({
                                          open: true,
                                          url:
                                            object[keyname]?.url ||
                                            object[keyname],
                                        })
                                      )
                                    }}
                                  >
                                    {i + 1}.{' '}
                                    {getFileName(
                                      object[keyname]?.url || object[keyname]
                                    )}
                                  </div>
                                )
                              ) : (
                                object[keyname]
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>

                <div className='action'>
                  <div
                    className='item'
                    onClick={() => handleEditRow(index, dataField)}
                  >
                    Edit
                  </div>
                  <div
                    className='item'
                    onClick={() => removeRow(index, dataField)}
                  >
                    Delete
                  </div>
                </div>
              </SummaryRow>
            )
          })}
        </div>
      )

    case 'submit':
      return (
        <div
          style={{
            gridColumn: '1/-1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              style={{ width: 'max-content' }}
              variant='contained'
              onClick={submitHandler}
            >
              {view ? 'Confirm Changes' : 'Submit'}
            </Button>
          )}
        </div>
      )

    case 'searchAutocomplete':
      return (
        <Autocomplete
          style={{ width: '100%' }}
          disablePortal
          id='combo-box-demo'
          options={
            GlobalStore.getState()['startup']?.[dataField.category][
              dataField.subCategory
            ][dataField.fieldName]?.options || []
          }
          disableClearable
          // disabled={view}
          value={value}
          onChange={(event, newValue) => {
            handleChange(
              dataField.fieldName,
              newValue,
              dataField.category,
              dataField.subCategory
            )
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            if (params.inputValue != '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
                new: true,
              })
            }
            return filtered
          }}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
              return option
            }
            if (option.name) {
              return option.name
            }
            return option.title
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={options?.title}
              onChange={(e) => {
                // if (!view) {
                handleSearch(
                  dataField.fieldName,
                  e.target.value,
                  dataField.category,
                  dataField.subCategory
                )
                // }
              }}
              size='small'
            />
          )}
        />
      )

    case 'multipleText':
      return (
        <div style={{ gridColumn: '1/-1' }}>
          <FormLabel>{options.title}</FormLabel>
          <div style={{ display: 'flex', gap: '3rem', marginTop: '0.5rem' }}>
            {options.options.map((option, i) => {
              return (
                <TextField
                  key={i}
                  label={option.title}
                  id={option.id}
                  size='small'
                  onChange={(e) => {
                    handleChange(
                      option.id,
                      e.target.value,
                      dataField.category,
                      dataField.subCategory
                    )
                  }}
                  // disabled={view}
                  value={value[option.id]}
                  helperText={!!!value[option.id] ? helperText : ''}
                  error={!!!value[option.id]}
                  style={
                    isActive
                      ? options.gridColumn
                        ? { gridColumn: options.gridColumn }
                        : null
                      : { display: 'none' }
                  }
                />
              )
            })}
          </div>
        </div>
      )

    case 'chipInput':
      if (
        dataField.category == 'summary' &&
        repeatedFields.includes(dataField.fieldName)
      ) {
        let data = GlobalStore.getState().startup.contact.contact
        dataField.category = 'contact'
        dataField.subCategory = 'contact'
        helperText = data[dataField.fieldName]?.error || ''
        error = !!data[dataField.fieldName]?.error || false
        isActive = data[dataField.fieldName]?.active || false
        value = data[dataField.fieldName].value
      }

      return (
        <MuiChipsInput
          value={value}
          onChange={(value) => {
            handleChange(
              dataField.fieldName,
              value,
              dataField.category,
              dataField.subCategory
            )
          }}
          size={'small'}
          label={options.title}
          error={error}
          helperText={helperText}
          disabled={
            options.title == 'Founders' && dataField.category == 'summary'
          }
        />
      )

    case 'freesoloSelect':
      if (dataField.fieldName == 'domainAndSector') {
        options.options = industries
      }
      return (
        <Autocomplete
          multiple
          id='tags-filled'
          options={options.options.map((option) => option)}
          defaultValue={value}
          freeSolo
          onChange={(event, newValue) => {
            handleChange(
              dataField.fieldName,
              newValue,
              dataField.category,
              dataField.subCategory
            )
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={options.title}
              placeholder=''
              size='small'
            />
          )}
        />
      )

    case 'associationTable':
      return (
        <TableContainer
          style={
            isActive
              ? options.gridColumn
                ? { gridColumn: options.gridColumn }
                : null
              : { display: 'none' }
          }
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Scheme</TableCell>
                <TableCell>Call Number</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {value?.map((obj, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell style={{ textTransform: 'uppercase' }}>
                      {obj?.schemeId}
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        value={obj?.callNumber}
                        onChange={(e) =>
                          handleAssocChange(i, 'callNumber', e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        value={obj?.startDate || ''}
                        onChange={(e) =>
                          handleAssocChange(i, 'startDate', e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size='small'
                        value={obj?.endDate || ''}
                        onChange={(e) =>
                          handleAssocChange(i, 'endDate', e.target.value)
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <div onClick={() => removeAssocRow(i)}>
                        {Icons.delete}
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell align='center' colSpan={5}>
                  <CustomButton
                    title={'Add Row'}
                    onClickHandler={() => {
                      setAssociationDialogOpen(true)
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>

          <Dialog
            open={associationDialogOpen}
            onClose={() => setAssociationDialogOpen(false)}
            disableEscapeKeyDown
          >
            <DialogBody>
              <div
                className='closeButton'
                onClick={() => {
                  setAssociationDialogOpen(false)
                }}
              >
                {Icons.close}
              </div>
              <DialogTitle>Scheme Name</DialogTitle>
              <DialogContent>
                <div style={{ marginTop: '0.5rem' }}></div>
                <Autocomplete
                  style={{ width: '100%' }}
                  id='assicationTitle'
                  disablePortal
                  options={schemes || []}
                  freeSolo
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} label={'Name'} size='small' />
                  )}
                />
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '1rem',
                  }}
                >
                  {' '}
                  <CustomButton
                    title='Add'
                    onClickHandler={() => {
                      associationAddRow(
                        String(
                          document.getElementById('assicationTitle').value
                        ).toLowerCase()
                      )
                      setAssociationDialogOpen(false)
                    }}
                  />
                </div>
              </DialogContent>
            </DialogBody>
          </Dialog>
        </TableContainer>
      )
  }
}

const BuildImageViewer = ({
  image,
  i,
  removeFileFromArray,
  dataField,
  view,
}) => {
  const dispatch = globalDispatch()
  const name = getFileName(image?.url || image)
  const fileUrl = name.split('.')
  const fileType = fileUrl[fileUrl.length - 1]

  console.log(image)

  return (
    <div className='image-column'>
      <div className='image' key={i}>
        {view ? (
          <></>
        ) : (
          <div
            className='close'
            style={{ zIndex: 99 }}
            onClick={() => removeFileFromArray(i, dataField)}
          >
            {Icons.closeRound}
          </div>
        )}

        {!!image?.url == false && !!image == false ? (
          <img src={Gif.hourglass} alt={i} />
        ) : (
          <></>
        )}

        {image?.type == 'video' && (!!image?.url || image) ? (
          <video
            style={{ width: '100%', height: '100%' }}
            className='pointer'
            onClick={() => {
              dispatch(
                updateDocument({
                  url: image?.url || image,
                  open: true,
                })
              )
            }}
          >
            <source src={image?.url} />
          </video>
        ) : (
          <></>
        )}

        {image?.type == 'image' && (!!image?.url || image) ? (
          <img
            src={image.url}
            alt={i}
            className='pointer'
            onClick={() => {
              dispatch(
                updateDocument({
                  url: image?.url,
                  open: true,
                })
              )
            }}
          />
        ) : (
          <></>
        )}

        {!image?.type &&
        ['jpg', 'jpeg', 'avif', 'png', 'svg'].includes(fileType) &&
        (!!image.url || image) ? (
          <img
            src={image?.url || image}
            alt={i}
            className='pointer'
            onClick={() => {
              dispatch(
                updateDocument({
                  url: image?.url || image,
                  open: true,
                })
              )
            }}
          />
        ) : (
          <></>
        )}

        {!image?.type &&
        ['mp4'].includes(fileType) &&
        (!!image.url || image) ? (
          <video
            style={{ width: '100%', height: '100%' }}
            className='pointer'
            onClick={() => {
              dispatch(
                updateDocument({
                  url: image?.url || image,
                  open: true,
                })
              )
            }}
          >
            <source src={image?.url || image} />
          </video>
        ) : (
          <></>
        )}
      </div>

      <div className='name'>{image?.error ? image?.error : image?.name}</div>
    </div>
  )
}

export const BuildFileUploader = ({
  options,
  dataField,
  handleFile,
  file,
  error,
  helperText,
  removeFile,
  label = null,
  view,
  disabled = false,
}) => {
  const dispatch = globalDispatch()

  let hasUrl = !!file?.url
  let url = ''
  if (String(file).substring(0, 5) == 'https') {
    hasUrl = true
    url = file
  } else if (hasUrl) {
    url = file?.url
  }
  return (
    <FileComponent>
      <FormLabel>{options.title}</FormLabel>

      {!hasUrl ? (
        <Button
          component='label'
          variant='contained'
          style={{ width: '100%' }}
          disableRipple={hasUrl}
          disabled={file?.loading || view}
        >
          <>
            {Icons.uploadFile}&nbsp;{label ? label : 'No File Chosen'}
            <input
              type={'file'}
              id={dataField.fieldName}
              accept='application/pdf'
              onChange={(e) => handleFile(e, dataField)}
              hidden
            />
          </>
        </Button>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <span>
            <div
              className='pointer'
              onClick={() => {
                dispatch(
                  updateDocument({
                    open: true,
                    url: url,
                  })
                )
              }}
            >
              {getFileName(url)}
            </div>
          </span>
          {disabled ? null : (
            <span
              onClick={(e) => {
                removeFile(dataField)
                e.preventDefault()
              }}
              className='delete'
            >
              {Icons.delete}
            </span>
          )}
        </div>
      )}

      <FormHelperText style={error ? { color: 'var(--errorTextColor)' } : null}>
        &nbsp;&nbsp;&nbsp;&nbsp;{helperText ? helperText : <>&nbsp;</>}
      </FormHelperText>
    </FileComponent>
  )
}

const SummaryRow = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  gap: 1rem;

  .content {
    border-radius: var(--borderRadius);
    padding: 1rem;
    border: 1px solid gray;
    max-height: 10rem;
    overflow: auto;
    width: 100%;

    .row {
      margin-bottom: 1rem;
      width: 100%;

      .heading {
        color: var(--text1);
        font-size: 1rem;
      }

      .desc {
        font-size: 0.9rem;
        margin-top: 0.2rem;
        word-break: break-all;
      }
    }
  }

  .action {
    display: flex;
    gap: 1rem;
    cursor: pointer;
  }
`

const FileComponent = styled.div`
  .delete {
    display: flex;
    align-items: center;
    transition: 0.3s color;

    &:hover {
      color: red;
    }
  }
`
