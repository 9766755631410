import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const theadings = [
  {
    title: "Sl No.",
    width: "50px",
  },
  {
    title: "Titan User ID",
    width: "",
  },
  {
    title: "Name of the Applicant",
    width: "",
  },
  {
    title: "Titan Project ID",
    width: "",
  },
  {
    title: "Startup Details",
    width: "",
  },
  {
    title: "Relevance",
    width: "",
  },
  {
    title: "Effectiveness",
    width: "",
  },
  {
    title: "Impact",
    width: "",
  },
  {
    title: "Originality and Innovation",
    width: "",
  },
  {
    title: "Presentation",
    width: "",
  },

  {
    title: "Total Score",
    width: "",
  },
  {
    title: "Recommend",
    width: "",
  },
  {
    title: "Comments",
    width: "",
  },
];

const PersonTable = ({
  person,
  applications,
  callId,
  reviewers,
  setSelectedApplications,
}) => {
  const navigate = useNavigate();
  const reviewerData = reviewers?.filter((Data) => Data?.abbr === person);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {theadings?.map((heading, i) => {
              return (
                <TableCell align="center" width={heading.width} key={i}>
                  {heading.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {applications
            ?.filter((application) => {
              if (
                application?.applicationData?.phase2?.data?.[
                  reviewerData[0]?.id
                ]
              ) {
                return application;
              }
            })
            ?.map((application, i) => {
              const data =
                application?.applicationData?.phase2?.data?.[
                  reviewerData[0]?.id
                ];
              return (
                data && (
                  <TableRow
                    key={i}
                    onClick={() =>
                      navigate(`/schemes/titan/${callId}/${application?._id}`)
                    }
                    className="pointer"
                  >
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">
                      {application?.userId ? application?.userId : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {application?.applicantName
                        ? application?.applicantName
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {application?.projectId ? application?.projectId : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {application?.companyName}
                    </TableCell>
                    <TableCell align="center">{data?.clarity || 0}</TableCell>
                    <TableCell align="center">{data?.unmetNeed || 0}</TableCell>
                    <TableCell align="center">
                      {data?.customerPay || 0}
                    </TableCell>
                    <TableCell align="center">{data?.novelty || 0}</TableCell>
                    <TableCell align="center">
                      {data?.techFeasibility || 0}
                    </TableCell>
                    <TableCell align="center">
                      {data?.teamStrength || 0}
                    </TableCell>
                    <TableCell align="center">{data?.total || 0}</TableCell>
                    <TableCell align="center">
                      {data?.recommend ? "Yes" : "No" || "No"}
                    </TableCell>
                    <TableCell align="center">{data?.comment || "-"}</TableCell>
                  </TableRow>
                )
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PersonTable;
