import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  applicationCount,
  fetchApplications,
  getOnlineReviewApplicationsTitan,
} from "../../../../../../../data/network/api/scheme";
import {
  globalDispatch,
  globalSelector,
} from "../../../../../../../providers/GlobalProvider";
import { TitanDispatch } from "../../../../../../../providers/scheme/titan/TitanProvider";
import { updateTitanNotification1 } from "../../../../../../../reducers/schemes/titan/tabs";
import CustomButton from "../../../../../../customComponents/CustomButton";
import CustomTextSearch from "../../../../../../customComponents/CustomSearchText";
import { SchemePhaseTabContainer } from "../../../../big/phases/ikp/Applications";
import { tabs } from "../../Applications";
import FinalistsTable from "./widgets/FinalistsTable";
import PersonTable from "./widgets/PersonTable";
import ReviewTab from "./widgets/ReviewTab";
import ScoreCardTable from "./widgets/ScoreCardTable";
import MultipleSelectCheckmarks from "../../../../../../customComponents/CustomMultiSelect";
import ExportExcel from "../../../../../../customComponents/ExportExcel";

const OnlineReview = ({ call }) => {
  const [activeReviewTab, setActiveReviewTab] = useState(0);
  const [applications, setApplications] = useState([]);
  const [filterApply, setFilterApply] = useState(false);
  const [selectedApplications, setSelectedApplications] = useState([]);
  const controller = new AbortController();
  const dispatch = globalDispatch();
  const titanDispatch = TitanDispatch();
  const user = globalSelector((state) => state.auth.user);
  const [reviewers, setReviewers] = useState([]);
  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const [filteredReviewers, setFilteredReviewers] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [excelData2, setExcelData2] = useState([]);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const [searchText, setSearchText] = useState("");
  //creating the excel data
  const onCreateExcelData = (
    arr,
    phase2ReviewerData,
    index,
    application,
    name
  ) => {
    let data = {
      "Sl No.": index,
      "Titan User ID": application?.userId ? application?.userId : "-",
      "Name of the Applicant": application?.applicantName
        ? application?.applicantName
        : "-",
      "Startup Details": application?.companyName,
      "Titan Project ID": application?.projectId ? application?.projectId : "-",
      "Project Title": application?.projectTitle
        ? application?.projectTitle
        : "-",
      Relevance: phase2ReviewerData?.clarity || 0,
      Effectiveness: phase2ReviewerData?.unmetNeed || 0,
      Impact: phase2ReviewerData?.customerPay || 0,
      "Originality and Innovation": phase2ReviewerData?.novelty || 0,
      Presentation: phase2ReviewerData?.techFeasibility || 0,
      "Total Score": phase2ReviewerData?.total || 0,
      Recommend: phase2ReviewerData?.recommend,
      Comments: phase2ReviewerData?.comment || "-",
      "Reviewer Name": name,
    };
    arr.push(data);
  };
  //adding the exceldata
  const onAddExcelData = (applicationsData, reviewersData) => {
    let slNo = 0;
    let arr = [];
    const AllConsolidatedData = [];
    applicationsData?.map((application, index) => {
      let consolidatedData = {
        "sl No": index + 1,
        "Titan User ID": application?.userId ? application?.userId : "-",
        "Name of the Applicant": application?.applicantName
          ? application?.applicantName
          : "-",
        "Startup Name": application?.companyName,
        "Titan Project ID": application?.projectId
          ? application?.projectId
          : "-",
        "Project Title": application?.projectTitle
          ? application?.projectTitle
          : "-",

        Sector: "-",
      };
      let applicationTotal = 0;
      reviewersData?.map((Data, index) => {
        const data = application?.applicationData?.phase2?.data?.[Data?.id];
        if (data) {
          slNo += 1;
          onCreateExcelData(arr, data, slNo, application, Data?.abbr);
          consolidatedData[Data?.abbr] = data?.total;
          applicationTotal += data?.total;
        } else {
          consolidatedData[Data?.abbr] = 0;
        }
      });

      AllConsolidatedData.push({
        ...consolidatedData,
        Total: applicationTotal,
      });
    });
    setExcelData2(AllConsolidatedData);
    setExcelData(arr);
    setIsExportExcel(true);
  };
  useEffect(() => {
    getApplications();
  }, []);
  useEffect(() => {
    if (selectedReviewers?.length > 0) {
      setFilterApply(false);
    } else {
      setFilterApply(true);
      setSelectedApplications([]);
    }
  }, [selectedReviewers]);

  const getApplications = async () => {
    // applicationCount({ callId: call._id }, dispatch, controller).then(
    // 	(response) => {
    // 		Object?.keys(tabs)?.map((key, i) => {
    // 			titanDispatch(
    // 				updateTitanNotification1({
    // 					id: tabs[key],
    // 					value: response.msg?.[i] || 0,
    // 				})
    // 			);
    // 		});
    // 	}
    // );
    const response = await fetchApplications(
      { callId: call._id, phase: 2 },
      dispatch,
      controller
    );

    if (response && response.status == 200) {
      titanDispatch(
        updateTitanNotification1({
          id: "review",
          value: response.msg?.applications?.length || 0,
        })
      );
      Object?.keys(tabs)?.map((key, i) => {
        titanDispatch(
          updateTitanNotification1({
            id: tabs[key],
            value: response.msg?.totalApplications || 0,
          })
        );
      });
      setApplications(response.msg?.applications);
      setSelectedApplications(response.msg?.applications);
      let reviewers = new Set();
      response?.msg?.programManagers?.map((manager, i) => {
        return reviewers.add(
          JSON.stringify({ _id: manager._id, name: manager.name })
        );
      });
      for (var i = 0; i < response?.msg?.applications?.length; i++) {
        response?.msg?.applications[i]?.reviewer?.map((reviewer, i) => {
          reviewers.add(JSON.stringify(reviewer));
        });
      }
      const formattedSet = [...reviewers]?.map((item) => {
        if (typeof item === "string") return JSON.parse(item);
        else if (typeof item === "object") return item;
      });

      const sampleReveiwersData = [];
      const reveiwersData = [];
      formattedSet?.map((data) => {
        if (sampleReveiwersData.indexOf(data?._id) === -1) {
          if (data?.name === "reviewer") {
            sampleReveiwersData.push(data?._id);
            reveiwersData.push({ abbr: data?.email, id: data?._id });
          } else {
            sampleReveiwersData.push(data?._id);
            reveiwersData.push({ abbr: data?.name, id: data?._id });
          }
        }
      });

      onAddExcelData(response?.msg?.applications, reveiwersData);
      const nameData = reveiwersData.map((data) => data?.abbr);
      setFilteredReviewers([...nameData]);
      setReviewers([...reveiwersData]);
      setSelectedReviewers([...nameData]);
    }
  };
  const ReviewTable = () => {
    if (activeReviewTab < selectedReviewers.length) {
      return (
        <PersonTable
          person={selectedReviewers[activeReviewTab]}
          reviewers={reviewers}
          applications={applications}
          callId={call?._id}
          setSelectedApplications={setSelectedApplications}
        />
      );
    }
    switch (reviewers.length) {
      case reviewers?.length:
        return (
          <>
            <ScoreCardTable persons={reviewers} applications={applications} />
          </>
        );
      // case persons.length + 1:
      //   return <FinalistsTable />
    }
  };

  const handleFilter = () => {
    const reviewerData = reviewers?.filter(
      (Data) => selectedReviewers.indexOf(Data?.abbr) !== -1
    );
    onAddExcelData(applications, reviewerData);
    setFilteredReviewers([...selectedReviewers]);
    setFilterApply(true);
  };
  return (
    <SchemePhaseTabContainer>
      <div className="topRow">
        <CustomTextSearch
          value={searchText}
          setValue={(value) => setSearchText(value)}
          upcomingBanner={true}
        />
        {/* <CustomButton
					title="Add New Applicant"
					onClickHandler={() => setOpen(true)}
				/> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <MultipleSelectCheckmarks
            Title={"reviewers"}
            setState={setSelectedReviewers}
            data={reviewers}
          />

          <CustomButton
            title={"Apply"}
            disabled={filterApply}
            onClickHandler={handleFilter}
          ></CustomButton>
        </div>
        <ExportExcel
          excelData={excelData}
          fileName={`Titan online review` + new Date().toLocaleDateString()}
          disabled={isExportExcel ? false : true}
          excelData2={excelData2}
          name={"Export ScoreCard"}
        />
      </div>
      <ReviewTab
        person={filteredReviewers}
        activeReviewTab={activeReviewTab}
        setActiveReviewTab={setActiveReviewTab}
      />

      <ReviewTabContainer>
        <ReviewTable />
      </ReviewTabContainer>
    </SchemePhaseTabContainer>
  );
};

export default OnlineReview;

const ReviewTabContainer = styled.div`
  margin-top: 1rem;
`;
