import { globalDispatch } from "../../../providers/GlobalProvider";
import { getRequest, postRequest } from "../../../utils/axiosClient/axios";
import { getToken } from "./startupForm";
import { logout } from "./user";

export const getLatestSchemes = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getLatestSchemes",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const getCategories = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }
  const response = await getRequest({
    endpoint: "/startups/industries",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });

  return response;
};

export const getScheme = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getAssociations",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const getCustomReportData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/startups/generateReport",
    data: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const getAllCalls = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/calls",
    data: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const callRegister = async (data, dispatch, controller) => {
  const response = await postRequest({
    endpoint: "/scheme/callRegister",
    data: data,
    dispatch,
    controller,
  });
  return response;
};

export const getSchemeAssociates = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getSchemeAssociates",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const assignAssociateForApplication = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/assignAssociate",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const inviteReviewerForApplication = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/inviteReviewers",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const sendInvite = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/sendInvite",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const applyCall = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/applyCall",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//to get all applications id data
export const getAllApplicationsID = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getAllApplicationIds",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
/**------------------------------------------------- */
export const fetchApplications = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/fetchApplications2",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
// get all applications data
export const fetchAllApplicationsData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/fetchApplications",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
// to get the filters data in the titan main page
export const getFilterersData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/titanFilters2",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//to get the data of the applications based on the selected date
export const getStatusToTitanFilterResults = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/exportResults2",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
// apply filters in the titan main page to get the filtered application
export const applyFilteres = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/filterApplications",
    data: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const applicationCount = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/applicationCount",
    data: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const applicationsProgress = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/applicationsProgress",
    data: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request from founder side to check for application
export const checkApplication = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/application",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const assignReviewersForApplications = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/assignReviewer",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const revokeReviewersForApplications = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/revokeReviewer",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const getAllReviewersData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getAllReviewers",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
// to get the application for the reviewer
export const getApplicationsByReviewer = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getApplicationsByReviewer",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
// to get the list of the reviewers Data
export const getApplicationReviewerData = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await getRequest({
    endpoint: "/scheme/getApplicationReviewer",
    params: data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//api request to get application data from application Id
export const getAppicationData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getApplicationData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to save application data for big
export const saveApplicationData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveApplicationData_Big",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to save form inputs
export const saveFormInputs = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveFormInputs",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to save workplan inputs
export const saveWorkplanInputs = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveWorkplanData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get application subphase data
export const getApplicationSubphaseData = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getApplicationPhaseData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get formdata
export const getFormData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/formData",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get workplandata
export const getWorkplanData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/workplan",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to update phase data of application data
export const updatePhaseData = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/updatePhase",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to update approved amount
export const approveAmount = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/approveAmount",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to upload agreement amount
export const uploadAgreement = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/uploadAgreement",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to submit milestone details and create project
export const submitMilestoneDetails = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/submitMilestoneDetails",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get milestone details of project
export const getProjectDetails = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/projectDetails",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to add comments
export const saveComment = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/saveComment",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to change status of application
export const changeApplicationStatus = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/changeApplicationStatus",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
export const rejectApplications = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/rejectApplications",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const moveToNextRound = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/moveToNext",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//api request to get call details
export const getSchemeDetails = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/getCallDetails",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

export const getOnlineReviewApplicationsPrayas = async (
  data,
  dispatch,
  controller
) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/prayas/onlineReviewApplications",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get all documents related to a application of scheme
export const getAllDocumentsScheme = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/documents",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};

//api request to get all documents related to a application of scheme
export const moveToDb = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/moveToDb",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//api request to bulk upload the applications
export const bulkUploadApplications = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/bulkUploadApplications",
    data,
    controller,
    dispatch,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
//api request to get all the schemes
export const fetchSchemes = async (data, dispatch, controller) => {
  const token = getToken();
  if (!token) {
    logout(dispatch);
    return null;
  }

  const response = await postRequest({
    endpoint: "/scheme/allSchemes",
    data,
    dispatch,
    controller,
    headers: {
      "access-token": token,
    },
  });
  return response;
};
