import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import CustomButton from "./CustomButton";
import styled from "styled-components";
import { fetchAllApplicationsData } from "../../data/network/api/scheme";
import { useParams } from "react-router-dom";
import { controllers } from "chart.js";
import { useState } from "react";
const ExportExcel = ({
  excelData,
  fileName,
  disabled,
  excelData2,
  name = null,
  isExportAllData = null,
  dispatch,
  controller,
  excelDataAddition,
}) => {
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { id } = useParams();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    if (isExportAllData) {
      setDownloadingReport(true);
      const response = await fetchAllApplicationsData(
        {
          callId: id,
          phase: 1,
        },
        dispatch,
        controller
      );
      excelDataAddition(response);
      setDownloadingReport(false);
      const ws = XLSX.utils.json_to_sheet(excelData);

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      const ws = XLSX.utils.json_to_sheet(excelData);
      let ws2 = "";
      let wb = "";
      if (excelData2) {
        ws2 = XLSX.utils.json_to_sheet(excelData2);
        wb = {
          Sheets: { data: ws, "Consolidated Data": ws2 },
          SheetNames: ["data", "Consolidated Data"],
        };
      } else {
        wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      }
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <Container>
      <CustomButton
        title={
          downloadingReport
            ? "Excel Exporting..."
            : name
            ? name
            : "Excel Export"
        }
        onClickHandler={exportToExcel}
        disabled={disabled}
      />
    </Container>
  );
};

export default ExportExcel;

const Container = styled.div``;
